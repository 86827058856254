<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'GET',
    };
  },
  mounted() {
    this.getConfigList('cash_withdrawal_details_list');
  },
  methods: {
    modalClick({
      val,
      row,
    }) {
      let urls = '';
      let text = '';
      if (val.code === 'money') {
        if (this.selection.length === 0) {
          return this.$message.error('请先选择需要打款的数据');
        }
        this.$confirm('是否确认打款？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            urls = '/cps-mobile-haipu/haipu/terminalCash/api/pay';
            text = '打款';
            if (row.id) {
              this.selection.push(row.id);
            }
            let isCash = true;
            const list = this.tableData;
            list.forEach((item) => {
              this.selection.forEach((v) => {
                if (v === item.id) {
                  if (item.cashStatus === '1') {
                    isCash = false;
                  }
                }
              });
            });
            if (!isCash) {
              this.$message.error('本次选择数据中有提现已打款成功，请勿重复打款');
              return false;
            }
            this.operationClick(urls, text);
            // if (row.id) {
            //   this.selection.push(row.id);
            // }
            // const list = this.tableData;
            // list.forEach((item) => {
            //   this.selection.forEach((v) => {
            //     if (v === item.id) {
            //       if (item.cashStatus === '2') {
            //         this.operationClick(urls, text);
            //       } else {
            //         return this.$message.error('本次提现已打款成功，请勿重复打款');
            //       }
            //     }
            //   });
            // });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            });
          });
      }
    },
    // 确认打款操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>
